<template>
  <article class="partner-page">
    <h1 class="sr-only">Electrify America Lyft Page</h1>
    <Hero :heading="headline" headlineLevel="h2" :subheading="true">
      <template #image>
        <img src="@/assets/images/Lyft/hero.png" alt="Electrify America charging stations in a desert climate" />
      </template>
    </Hero>

    <TwoColumnContentBlock heading="Evolve with Electrify America" id="select-model" headlineLevel="h3">
      <p>
        The future of electric mobility can’t wait, and with Electrify America, it doesn’t have to. We’ve built a
        convenient, reliable, and growing network of over 4,250 electric vehicle chargers coast-to-coast—along major
        highways and routes—to take electric vehicle (EV) drivers farther than ever before.
      </p>
      <PartnerDropdown id="lyft-select" v-model="modelChosen" :options="LyftDropdownOptions">
        <template #label>
          <div>
            <label for="lyft-select">Select your Lyft Plan</label>
          </div>
        </template>
      </PartnerDropdown>
      <p v-if="modelChosen === null">
        Lyft drivers can focus on the roads and possibilities ahead, leaving gas stations and MPG behind.
      </p>
      <p v-else-if="modelChosen === 'Lyft Standard Discount Plan'">
        Your
        <strong
          >Lyft Standard Discount Plan<reference-link :num="1"
            >Available for active Lyft drivers. Discount does not apply any idle fees and their associated or applicable
            taxes assessed at the charging station. You will be responsible for paying such fees through your account if
            you incur them during a charging session. Non-transferable. Requires Electrify America app, account,
            enrollment in Lyft Standard Discount Plan, and acceptance of Terms of Use. After user-initiated charge
            session stop or vehicle reaches full charge or charging otherwise stops, and 10-minute grace period, idle
            fees and applicable taxes will apply, and user is responsible.</reference-link
          ></strong
        >
        provides discounts off Electrify America Pass pricing at Electrify America-branded charging stations.
        Energy-based pricing locations will receive a discount of $0.11/kWh. Time-based pricing locations will receive a
        discount of $0.03/min for 1-90 kW, or $0.07/min for 1-350 kW, based on the maximum charge rate your vehicle can
        accept. For full program details, click
        <router-link
          class="link"
          :to="{
            name: 'lyft-standard-discount-plan-disclosure-en',
          }"
          >here</router-link
        >.
      </p>
      <p v-else>
        Your
        <strong
          >Lyft Gold & Platinum Discount Plan
          <reference-link :num="1"
            >Available for active Lyft Rewards Gold & Platinum drivers . Discount does not apply any idle fees and their
            associated or applicable taxes assessed at the charging station. You will be responsible for paying such
            fees through your account if you incur them during a charging session. Non-transferable. Requires Electrify
            America app, account, enrollment in Lyft Gold & Platinum Discount Plan, and acceptance of Terms of Use.
            After user-initiated charge session stop or vehicle reaches full charge or charging otherwise stops, and
            10-minute grace period, idle fees and applicable taxes will apply, and user is responsible.</reference-link
          ></strong
        >
        provides discounts off Electrify America Pass pricing at Electrify America-branded charging stations.
        Energy-based pricing locations will receive a discount of $0.14/kWh. Time-based pricing locations will receive a
        discount of $0.04/min for 1-90 kW, or $0.09/min for 1-350 kW, based on the maximum charge rate your vehicle can
        accept. For full program details, click
        <router-link
          class="link"
          :to="{
            name: 'lyft-gold-and-platinum-discount-plan-disclosure-en',
          }"
          >here</router-link
        >.
      </p>
      <router-link class="button" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Enroll and save on Ultra-Fast charging" headlineLevel="h3">
      <template #image>
        <picture>
          <source media="(min-width: 750px)" srcset="@/assets/images/Genesis/go-with-freedom.png" />
          <img loading="lazy" src="@/assets/images/Genesis/go-with-freedom-mobile.png" alt=""
        /></picture>
      </template>
      <p v-if="modelChosen === null">
        Eligible Lyft drivers can get discounted charging on Electrify America’s Ultra-Fast charging network. Select
        your Lyft Plan to learn about available benefits and how to enroll.
      </p>
      <section v-else>
        <p>To access your benefits, follow these simple steps:</p>
        <ul>
          <li>
            <strong>Step 1:</strong> Download the Electrify America app
            <ReferenceLink :num="2"
              >Your mobile carrier’s messaging, data, and other rates and fees will apply to your use of the Electrify
              America mobile application.</ReferenceLink
            >, create your account, and select “Set Up Public Charging.”
          </li>
          <li>
            <strong>Step 2:</strong> Select “Choose Membership Plan” then “Premium Offers.”
            <ul>
              <li>Or search for Premium Offer by vehicle information.</li>
              <li>
                Existing Electrify America members may select “Premium Offers” in the Account tab, then “I have an
                enrollment code.”
              </li>
            </ul>
          </li>
          <li>
            <strong>Step 3:</strong>
            : Input your enrollment code, which you will receive from Lyft or select your
            <strong>{{ modelChosen }}</strong
            >. Input your vehicle’s 17-digit VIN
            <reference-link :num="3"
              >The VIN can usually be found on the driver’s side dashboard, near or under the bottom portion of the
              windshield</reference-link
            >
            for verification.
          </li>
          <li><strong>Step 4:</strong> Accept Plan Disclosure, then tap “Select this plan.”</li>
          <li>
            <strong>Step 5:</strong> Enter payment info and set up auto-reload.
            <ul>
              <li>
                You will need to enter a payment method
                <reference-link :num="4"
                  >To help ensure you can charge uninterrupted, your Electrify America account balance automatically
                  reloads when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the
                  first time you charge at an Electrify America station, and the billed amount will be held in your
                  account to cover costs not covered by this plan, such as idle fees and applicable
                  taxes.</reference-link
                >
                to cover any incurred idle fees
                <reference-link :num="5"
                  >Drivers who do not unplug and move their vehicle ten minutes after their charging session is complete
                  will be charged a $0.40 per-minute idle fee. This fee encourages drivers to move their car promptly so
                  that others can use the charger. You can be notified when your charging session is complete by text
                  message (your mobile carrier’s messaging, data and other rates may apply) or see it on the charger
                  screen.</reference-link
                >
                or applicable taxes.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Offer enrollment can be as smooth as your journeys, but if you encounter any roadblocks, call
          <strong>1-833-632-2778</strong>
          to contact Electrify America Customer Assistance, available 24/7.
        </p>
      </section>
      <router-link v-if="modelChosen !== null" class="button" :to="{ name: 'mobile-app-en' }"
        >Download the App</router-link
      >
      <a v-else class="button" href="#select-model">SELECT YOUR PLAN</a>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import PartnerDropdown from '@/components/PartnerDropdown/PartnerDropdown.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';
import ReferenceLink from '../../components/ReferenceLink/ReferenceLink.vue';

export default {
  components: {
    PartnerDropdown,
    Hero,
    TwoColumnContentBlock,
    ReferenceLink,
  },
  metaInfo: {
    title: 'Lyft | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/lyft/' }],
  },
  data() {
    return {
      LyftDropdownOptions: [
        { text: 'Select...', value: null },
        { text: 'Lyft Standard Discount Plan', value: 'Lyft Standard Discount Plan' },
        { text: 'Lyft Gold & Platinum Discount Plan', value: 'Lyft Gold & Platinum Discount Plan' },
      ],
      modelChosen: null,
    };
  },
  computed: {
    lastWordFromString() {
      const n = this.modelChosen.split(' ');
      return n[n.length - 1];
    },
    headline() {
      if (this.$resize && this.$mq.below(548)) {
        return `Embrace the full potential of your electric vehicle with Lyft`;
      }
      return `Embrace the full potential of your electric vehicle with Lyft`;
    },
  },
};
</script>
